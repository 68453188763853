var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "overlayscrollbars_page flex" },
    [
      _c(
        "overlay-scrollbars",
        { ref: "overlay", staticClass: "text_group flex" },
        [
          _c("div", { staticClass: "group flex" }, [
            _c("div", { staticClass: "unit" }, [_vm._v("第一个sd,jbfsjh ")]),
            _c("div", { staticClass: "unit" }, [_vm._v("第一个sd,jbfsjh ")]),
            _c("div", { staticClass: "unit" }, [_vm._v("第一个sd,jbfsjh ")]),
            _c("div", { staticClass: "unit" }, [_vm._v("第一个sd,jbfsjh ")]),
            _c("div", { staticClass: "unit" }, [_vm._v("第一个sd,jbfsjh ")]),
            _c("div", { staticClass: "unit" }, [_vm._v("第一个sd,jbfsjh ")]),
            _c("div", { staticClass: "unit" }, [_vm._v("第一个sd,jbfsjh ")]),
            _c("div", { staticClass: "unit" }, [_vm._v("第一个sd,jbfsjh ")]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }