<!--
 * @Author       : Hugo
 * @Date         : 2021-04-30 11:24:30
 * @LastEditTime : 2021-04-30 12:41:31
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/views/test/overlayscrollbars.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div class="overlayscrollbars_page flex">
    <overlay-scrollbars
      class="text_group flex"
      ref="overlay"
    > 
    <div class="group flex">
        <div class="unit">第一个sd,jbfsjh </div>
        <div class="unit">第一个sd,jbfsjh </div>
        <div class="unit">第一个sd,jbfsjh </div>
        <div class="unit">第一个sd,jbfsjh </div>
        <div class="unit">第一个sd,jbfsjh </div>
        <div class="unit">第一个sd,jbfsjh </div>
        <div class="unit">第一个sd,jbfsjh </div>
        <div class="unit">第一个sd,jbfsjh </div>
    </div>
    </overlay-scrollbars>
    
  </div>
</template>

<script>
export default {
  data() {
    return {
      options: {
        overflowBehavior : {
          x : "scroll",
          y : "scroll"
        },
      }
    }
  },
  mounted(){
    setTimeout(()=>{
      // // console.log(this.$refs.overlay)
      this.$refs.overlay.osInstance().scroll({x: '100%'});

    },3000)
  }
};
</script>

<style lang="scss" scoped>
.overlayscrollbars_page{
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #999;
  .text_group{
    @include flex;
    width: 400px;
    height: 200px;
    background-color:#fff;
    .group{
      
    }
    .unit{
      flex-shrink: 0;
    }
    
  }
}
</style>

<style scoped>
.overlayscrollbars_page .os-content{
  display: flex;
}
</style>